// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//= require activestorage
//= require.self
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

// jquery
import $ from 'jquery'
global.$ = $
global.jQuery = $
require('jquery-ui')
import 'bootstrap/dist/js/bootstrap'
import 'bootstrap/dist/css/bootstrap'

Rails.start()
Turbolinks.start()
ActiveStorage.start()


addEventListener("direct-upload:initialize", event => {
    const { target, detail } = event
    const { id, file } = detail
    target.insertAdjacentHTML("beforebegin", `
    <div id="direct-upload-${id}" class="direct-upload progress">
      <div id="direct-upload-progress-${id}" class="progress-bar progress-bar-striped bg-info" style="width: 0%"><span class="direct-upload__filename"></span></div>
      
    </div>
  `)
    target.previousElementSibling.querySelector(`.direct-upload__filename`).textContent = file.name
})

addEventListener("direct-upload:start", event => {
    const { id } = event.detail
    const element = document.getElementById(`direct-upload-progress-${id}`)
    element.classList.remove("bg-info").add("progress-bar-animated")
})

addEventListener("direct-upload:progress", event => {
    const { id, progress } = event.detail
    const progressElement = document.getElementById(`direct-upload-progress-${id}`)
    progressElement.style.width = `${progress}%`
})

addEventListener("direct-upload:error", event => {
    event.preventDefault()
    const { id, error } = event.detail
    const element = document.getElementById(`direct-upload-progress-${id}`)
    element.classList.add("bg-danger")
    element.setAttribute("title", error)
})

addEventListener("direct-upload:end", event => {
    const { id } = event.detail
    const element = document.getElementById(`direct-upload-progress-${id}`)
    element.classList.add("bg-success")
})


addEventListener("turbolinks:load", event => {
    init()
});



function updateURLParameter(url, param, paramVal){
    var newAdditionalURL = "";
    var tempArray = url.split("?");
    var baseURL = tempArray[0];
    var additionalURL = tempArray[1];
    var temp = "";
    if (additionalURL) {
        tempArray = additionalURL.split("&");
        for (var i=0; i<tempArray.length; i++){
            if(tempArray[i].split('=')[0] != param){
                newAdditionalURL += temp + tempArray[i];
                temp = "&";
            }
        }
    }

    var rows_txt = temp + "" + param + "=" + paramVal;
    return baseURL + "?" + newAdditionalURL + rows_txt;
}

function updateLanguage (e){
    e.preventDefault();
    e.stopPropagation();
    let lang = $(e.currentTarget).val();
    window.location.href = updateURLParameter(window.location.href, 'lang', lang);

}

function initForms() {
    $("form").each(function() {
        $(this).on('submit',checkBeforeSubmit);
        $(this).find("input,textarea").each(function (){
            $(this).on('change',updateValidityByEvent);
            $(this).on('keyup',updateValidityByEvent);
            updateValidity(this);
        });
    })
}

function checkBeforeSubmit(e) {
    e.preventDefault();
    var form = e.currentTarget;
    var valid = true;

    $(form).find("input,textarea").each(function() {
        if (valid) valid = this.checkValidity();
    })

    if (valid) e.currentTarget.submit();
    $(form).find("input[type=\"submit\"]").attr("disabled",(!valid ? "disabled" : ""));
    return valid;
}

function updateValidityByEvent(e) {
    updateValidity(e.currentTarget);
}
function updateValidity(target){
    var parent = $(target).parent();
    var valid = target.checkValidity();
    $(target).removeClass('is-valid').removeClass('is-invalid').addClass('is-' + (valid ? '' : 'in') + 'valid');
    $(parent).removeClass('form-group-valid').removeClass('form-group-invalid').addClass('form-group-' + (valid ? '' : 'in') + 'valid');
}

function fixDirectUpload() {
    if (navigator.userAgent.indexOf("WebKit") !== -1) {
        $("form").find("input[type=\"file\"]").removeAttr("data-direct-upload-url");
    }
}

function init() {
    $('.sortable').sortable({
        activate: function (e,i) {
            $(this).addClass("dragging");
        },
        stop: function (e,i){
            $(this).removeClass("dragging");
        },
        update: function (e,i) {
            $.ajax({
                url: $(this).data("url"),
                type: "PATCH",
                data: $(this).sortable("serialize")
            })
        }
    });
    $("#langSel").change(updateLanguage);
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover();
    $('[data-toggle="collapse"]').collapse();
    initForms();
    fixDirectUpload();
}
